const baseSize = 16
function setRem () {
  // 当前页面宽度相对于 1440 宽的缩放比例 
  const scale = document.documentElement.clientWidth / 1440
  let fontSize = (baseSize * Math.min(scale, 2))>12 ? (baseSize * Math.min(scale, 2)): 12
  document.documentElement.style.fontSize = fontSize + 'px'
}
setRem()
window.onresize = function () {
  setRem()
}