<template>
  <router-view></router-view>
</template>
<script>
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
  setup() {
    document.title = 'ThalesLens | AIGC XR Filter';
    let route = useRoute();
    console.log('====',route.query, window.opener)
    if (route.query && route.query.access_token && window.opener) {
      localStorage.setItem('token1', JSON.stringify(route.query))
      window.opener.postMessage(
        {
          parentPath: window.opener.parent.location.hash.slice(1),
          type: "closeWorkflow",
        },
        "*"
      );
    }
    return {};
  },
});
</script>
<style lang="less">
body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #000000;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #6b7280;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1.5px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: white;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 1.5px rgba(0, 0, 0, 0.3);
  background-color: #6b7280;
}
</style>
