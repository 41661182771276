import { createApp } from 'vue'
import App from './App.vue'
import Routes from './routers/routes'
import less from 'less'
import './assets/css/index.css'
import './assets/css/flex.css'
import './assets/css/main.less'
import '@/assets/font/font.css'
import '@/utils/rem'
// import './assets/css/discord.css'
// import './utils/wallet/moralis'
// import 'animate.css/animate.min.css';

const app:any = createApp(App)
app.use(less).use(Routes).mount('#app')
