import {
    createRouter,
    createWebHashHistory,
    createWebHistory
} from "vue-router";
import { post } from '@/http/api'

const routes:any = [
    // {
    //     path: "/:pathMatch(.*)",
    //     redirect: '/index'
    // },
    {
        path: "/",
        redirect: '/index'
    },
    {
        path: "/index",
        name: "index",
        component: () => import("@/views/home/index.vue"),
        meta: { title: '首页' }
    },
    // {
    //     path: "/test",
    //     name: "test",
    //     component: () => import("@/views/home/test.vue"),
    //     meta: { title: 'explore' }
    // },
    // {
    //     path: "/test1",
    //     name: "test1",
    //     component: () => import("@/views/home/test1.vue"),
    //     meta: { title: 'explore' }
    // },
    {
        path: "/TermOfUse",
        name: "TermOfUse",
        component: () => import("@/views/home/TermOfUse.vue"),
        meta: { title: 'TermOfUse' }
    },
    {
        path: "/privacyPolicy",
        name: "privacyPolicy",
        component: () => import("@/views/home/privacyPolicy.vue"),
        meta: { title: 'privacyPolicy' }
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

const loadPageVar = (sVar:any) =>{
  return decodeURI(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURI(sVar).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}

router.beforeEach((to,from,next) => {
    console.log(window.location.origin)
    const fragment = new URLSearchParams(window.location.hash.slice(1));
    const [accessToken, tokenType, accessCode] = [fragment.get('access_token'), fragment.get('token_type'), loadPageVar('code')];
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('tokenType', tokenType || '');
        window.location.href = `${window.location.origin}/#/index`
        window.location.reload()
    } else if(accessCode) {
        localStorage.setItem('accessCode', accessCode);
        window.location.href = `${window.location.origin}/#/index`
        // window.location.reload()
    } else {
        next()
    }
    // else if(!to.name) {
    //     window.location.href = `${window.location.origin}/#/index${window.location.search + '&' +to.path.substring(1,to.path.length)}`
    // }
  })

export default router;
